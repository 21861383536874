import 'assets/main.scss';
import React from 'react';
import { render } from 'react-dom';
import 'react-loading-skeleton/dist/skeleton.css';
import { Provider } from 'react-redux';

import sublimeStore from 'redux/sublimeStore';

import MaintenanceRouter from 'routers/MaintenanceRouter';
import SublimeRouter from 'routers/SublimeRouter';

const maintenanceMode = process.env.MAINTENANCE_MODE;

const Root = () => {
  return maintenanceMode === 'on' ? (
    <MaintenanceRouter />
  ) : (
    <Provider store={sublimeStore}>
      <SublimeRouter />
    </Provider>
  );
};

const node = document.getElementById('root');

render(React.createElement(Root), node);
