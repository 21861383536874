import React from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import Lazyload from 'utils/Lazyload';

import Loader from 'components/atoms/Loader';

const NotFoundPublicContainer = React.lazy(() => import('pages/NotFoundPublicContainer'));
const PrivacyContainer = React.lazy(() => import('pages/legals/PrivacyContainer'));
const CookiesContainer = React.lazy(() => import('pages/legals/CookiesContainer'));
const LegalMentionsContainer = React.lazy(() => import('pages/legals/LegalMentionsContainer'));
const IntellectualPropertiesContainer = React.lazy(() =>
  import('pages/legals/IntellectualPropertiesContainer')
);
const OptoutContainer = React.lazy(() => import('pages/legals/OptoutContainer'));

const SublimeRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/legal-mentions/en" />} exact />
        <Route
          path="/legal-mentions/fr"
          element={
            <Lazyload loader={<Loader />}>
              <LegalMentionsContainer language="fr" />
            </Lazyload>
          }
          exact
        />
        <Route
          path="/legal-mentions/en"
          element={
            <Lazyload loader={<Loader />}>
              <LegalMentionsContainer language="en" />
            </Lazyload>
          }
          exact
        />
        <Route
          path="/cookies-policy/en"
          element={
            <Lazyload loader={<Loader />}>
              <CookiesContainer language="en" />
            </Lazyload>
          }
          exact
        />
        <Route
          path="/cookies-policy/fr"
          element={
            <Lazyload loader={<Loader />}>
              <CookiesContainer language="fr" />
            </Lazyload>
          }
          exact
        />
        <Route
          path="/privacy/en"
          element={
            <Lazyload loader={<Loader />}>
              <PrivacyContainer language="en" />
            </Lazyload>
          }
          exact
        />
        <Route
          path="/privacy/fr"
          element={
            <Lazyload loader={<Loader />}>
              <PrivacyContainer language="fr" />
            </Lazyload>
          }
          exact
        />
        <Route
          path="/intellectual-properties"
          element={
            <Lazyload loader={<Loader />}>
              <IntellectualPropertiesContainer />
            </Lazyload>
          }
          exact
        />
        <Route
          path="/optout"
          element={
            <Lazyload loader={<Loader />}>
              <OptoutContainer />
            </Lazyload>
          }
          exact
        />
        <Route
          path="*"
          element={
            <Lazyload loader={<Loader />}>
              <NotFoundPublicContainer />
            </Lazyload>
          }
        />
      </Routes>
    </Router>
  );
};

export default SublimeRouter;
