import { combineReducers } from 'redux';

import alertReducer from 'redux/features/alert/alertSlice';
import configsReducer from 'redux/features/configs/configsSlice';

const sublimeRootReducer = combineReducers({
  alert: alertReducer,
  configs: configsReducer,
});

export default sublimeRootReducer;
