import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

import sublimeRootReducer from './sublimeRootReducer';

const loggerMiddleware = createLogger();

let middlewares = [
  ...getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['dsps/fetchDsps/fulfilled', 'deliveryTypes/fetchDeliveryTypes/fulfilled'],
    },
  }),
];

if (process.env.NODE_ENV === 'development') {
  middlewares = [...middlewares, loggerMiddleware];
}

const sublimeStore = configureStore({
  reducer: sublimeRootReducer,
  middleware: middlewares,
  devTools: process.env.NODE_ENV !== 'production',
});

export const setupStore = preloadedState => {
  return configureStore({
    reducer: sublimeRootReducer,
    preloadedState,
  });
};

export default sublimeStore;
